import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders";
const LazyComponent = lazy(() => import("./NotificationDetails"));

export default function NotificationDetails() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
