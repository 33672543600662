import { Bars, TailSpin, Oval } from "react-loader-spinner";
import "./Loaders.scss";

export const LazyLoader = () => {
  return (
    <div className="loader-main">
      <Bars
        height="50"
        width="40"
        color="#110942"
        ariaLabel="bar-loading"
        visible={true}
      />
    </div>
  );
};

export const CustomerLazyLoader = () => {
  return (
    <div className="loader-main">
      <Bars
        height="50"
        width="40"
        color="#110942"
        ariaLabel="bar-loading"
        visible={true}
      />
    </div>
  );
};

export const CustomerPortalLoader = () => {
  return (
    <div className="loader-main-loader">
      <Oval
        visible={true}
        height="40"
        width="40"
        color="rgb(213, 36, 41)"
        ariaLabel="oval-loading"
      />
    </div>
  );
};

export const ButtonLoader = ({ color }) => {
  return (
    <TailSpin
      height="1.5rem"
      color={color ? color : "white"}
      radius="2"
      wrapperClass="loader-button"
      visible={true}
    />
  );
};
