import React, { useEffect, useState } from "react";
import "./App.css";
import Main from "./Main";
 
import Toaster from "./assets/elements/Toaster";

import { HashRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/Auth";
import { ConfigProvider } from "./context/Config"; 
import { NotificationProvider } from "./context/Notification";

function App() {
 

  return (
    <ConfigProvider>
      <AuthProvider>
        <NotificationProvider>
          <Router>
            <Toaster />
            <Main />
          </Router>
        </NotificationProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
